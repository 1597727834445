/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Global Styles */
.App-logo {
  height: 10vmin;
  pointer-events: none;
}
.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  color: #333;
}
a {
  text-decoration: none;
  color: inherit;
}

/* Header & Navigation */
header {
  background-color: #af1f22;
  color: white;
  padding: 20px 0;
  text-align: center;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}
.nav-links {
  list-style: none;
  display: flex;
}
.nav-links li {
  margin: 0 15px;
}
.nav-links a {
  color: white;
  font-weight: 500;
  transition: color 0.3s;
}
.nav-links a:hover {
  color: #f19a26;
}
.logo {
  font-size: 24px;
  font-weight: 700;
}
.cta {
  background-color: #f19a26;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  color: #333;
  transition: background-color 0.3s;
}
.cta:hover {
  background-color: #ffd83d;
}

/* Hero Section */
.hero {
  padding: 60px 30px;
  text-align: center;
}
.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
}
.hero p {
  font-size: 18px;
  margin-bottom: 30px;
}
.btn-primary,
.btn-secondary {
  padding: 12px 24px;
  border-radius: 5px;
  font-weight: 500;
}
.btn-primary {
  background-color: #f19a26;
  color: #333;
  margin-right: 10px;
}
.btn-secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}
.btn-primary:hover {
  background-color: #ffd83d;
}
.btn-secondary:hover {
  background-color: white;
  color: #af1f22;
}

/* Key Benefits Section */
.key-benefits {
  background-color: #fff;
  padding: 50px;
  text-align: center;
}
.benefits-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.benefit-item {
  width: 22%;
}
.benefit-item h3 {
  margin-bottom: 10px;
}

/* Services Section */
.services {
  padding: 50px;
  background-color: #f4f4f4;
  text-align: center;
}
.services-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.service-item {
  width: 22%;
}
.service-item h3 {
  margin-bottom: 10px;
}

/* How It Works Section */
.how-it-works {
  background-color: #fff;
  padding: 50px;
  text-align: center;
}
.steps-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.step-item {
  width: 30%;
}
.step-item h3 {
  margin-bottom: 10px;
}

/* Pricing Section */
.pricing {
  background-color: #f4f4f4;
  padding: 50px;
  text-align: center;
}
.pricing-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.pricing-item {
  width: 30%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.pricing-item h3 {
  margin-bottom: 10px;
}
.pricing-item .price {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

/* Contact Section */
.contact {
  padding: 50px;
  background-color: #fff;
  text-align: center;
}
.contact-form {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.contact-form label {
  margin-bottom: 5px;
}
.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Footer */
footer {
  background-color: #af1f22;
  color: white;
  padding: 20px 0;
  text-align: center;
}
.footer-links {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.footer-links li {
  margin: 0 15px;
}
.footer-links a {
  color: white;
  transition: color 0.3s;
}
.footer-links a:hover {
  color: #f19a26;
}
#about,
#services,
#contact {
  padding: 50px 20px;
}

html {
  scroll-behavior: smooth;
}

.aboutHeader {
  text-align: center !important;
}
.center {
  text-align: center;
}
